import { createContext, ReactNode, useMemo } from 'react';

import createAppStorage from 'utils/createAppStorage';
import useAppStorageState from '../../hooks/useAppStorageState';

interface BasicGraphRange {
  range: number;
}

interface CustomGraphRange {
  range: 'custom';
  rangeMin: number;
  rangeMax: number;
}

type GraphRange = BasicGraphRange | CustomGraphRange;

interface ObservationSettings {
  ranges: {
    [key: string]: GraphRange;
  };
}

interface ObservationSettingsActions {
  getRange: (key: string) => GraphRange;
  setRange: (key: string, range: GraphRange) => void;
}

export const observationSettingsStorage = createAppStorage<ObservationSettings>('intoto_observation_settings', {
  ranges: {},
});

export const ObservationSettingsContext = createContext<ObservationSettings & ObservationSettingsActions>({
  ranges: {},
  getRange: () => ({ range: 100 }),
  setRange: () => undefined,
});

const removeQueryFromId = (id: string) => id.split('?')[0];

function ObservationSettingsContextProvider({ children }: { children: ReactNode }) {
  const [settings, setSettings] = useAppStorageState(observationSettingsStorage);

  const value: ObservationSettings & ObservationSettingsActions = useMemo(
    () => ({
      ...settings,
      getRange: (key: string) => {
        const strippedKey = removeQueryFromId(key);

        if (settings.ranges[strippedKey]) {
          return settings.ranges[strippedKey];
        }

        return { range: 100 };
      },
      setRange: (key: string, range: GraphRange) => {
        const strippedKey = removeQueryFromId(key);

        setSettings({
          ...settings,
          ranges: {
            ...settings.ranges,
            [strippedKey]: range,
          },
        });
      },
    }),
    [setSettings, settings],
  );

  return <ObservationSettingsContext.Provider value={value}>{children}</ObservationSettingsContext.Provider>;
}

export default ObservationSettingsContextProvider;
