import Box from '@mui/material/Box';

import logo from './intoto-logo.svg';

interface LogoTextProps {
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
}

function LogoText({ src = logo, alt = 'Intoto Studio', width = 100, height = 23.16 }: LogoTextProps) {
  if (!src) return null;

  return <Box component="img" src={src} alt={alt} width={width} height={height} sx={{ ml: !src ? 2 : 1, mr: 1 }} />;
}

export default LogoText;
